import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi'
import SpeedIcon from '@material-ui/icons/Speed'
import InputIcon from '@material-ui/icons/Input'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

// Store
import useStore from '../store/store'

// Helpers
import isPermissionGranted from '../helpers/isPermissionGranted.js'

// Consts
import Permissions from '../consts/Permissions'

const MainMenuItems = ({ activePage }) => {
  // Styles
  const classes = useStyles()

  const title = useStore((state) => state.title)

  return (
    <div>
      <ListItem button component={Link} to='/' replace>
        <ListItemIcon className={title === 'Gateway' ? classes.active : null}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText
          primary='Gateway'
          classes={{ primary: title === 'Gateway' ? classes.active : null }}
        />
      </ListItem>
      <ListItem button component={Link} to='/nodes' replace>
        <ListItemIcon className={title === 'Nodes' ? classes.active : null}>
          <SettingsInputHdmiIcon />
        </ListItemIcon>
        <ListItemText
          primary='Nodes'
          classes={{ primary: title === 'Nodes' ? classes.active : null }}
        />
      </ListItem>
      <ListItem button component={Link} to='/sensors' replace>
        <ListItemIcon className={title === 'Sensors' ? classes.active : null}>
          <SpeedIcon />
        </ListItemIcon>
        <ListItemText
          primary='Sensors'
          classes={{ primary: title === 'Sensors' ? classes.active : null }}
        />
      </ListItem>
      {isPermissionGranted(Permissions.logic_screen) && (
        <ListItem button component={Link} to='/logic' replace>
          <ListItemIcon className={title === 'Logic' ? classes.active : null}>
            <InputIcon />
          </ListItemIcon>
          <ListItemText
            primary='Logic'
            classes={{ primary: title === 'Logic' ? classes.active : null }}
          />
        </ListItem>
      )}
      {isPermissionGranted(Permissions.dashboard) && (
        <ListItem button component={Link} to='/dashboard' replace>
          <ListItemIcon
            className={title === 'Dashboard' ? classes.active : null}
          >
            <AnalyticsIcon />
          </ListItemIcon>
          <ListItemText
            primary='Dashboard'
            classes={{ primary: title === 'Dashboard' ? classes.active : null }}
          />
        </ListItem>
      )}
    </div>
  )
}

export default MainMenuItems

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}))
