import { produce } from 'immer'
import useStore from '../store/store'
import { sendRequest } from '../api/websocketApi'

const AddLogic = ({ name, description, jsonLogic, logic }) => {
  const setFormError = useStore.getState().setFormError
  const setLoading = useStore.getState().setLoading
  const logics = useStore.getState().logics
  const setLogics = useStore.getState().setLogics

  setLoading(true)
  // jsonLogic - ui description
  // logic - network logic
  const params = { name, jsonLogic, logic }
  if (description) {
    params.description = description
  }

  console.log(params)
  sendRequest(
    {
      endpoint: '/network-logic/add',
      body: params,
    },
    (data) => {
      const { success, userNetworkLogicId, errCode } = data
      if (success && userNetworkLogicId) {
        const newLogics = produce(logics, (draft) => {
          draft.push({
            UserNetworkLogicId: userNetworkLogicId,
            Name: name,
            Description: description,
            Active: 0,
            JsonLogic: JSON.parse(jsonLogic),
          })
        })
        setLogics(newLogics)
        setLoading(false)
      } else if (!success && errCode) {
        setLoading(false)
        setFormError(errCode)
      }
    }
  )
}

export default AddLogic
