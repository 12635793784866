import React, { useEffect } from 'react'
import { HashRouter as Router, Routes, Route } from 'react-router-dom'

// Pages
import LoginFormPage from './pages/LoginFormPage'
import RegisterFormPage from './pages/registration/RegisterFormPage'
import ConfirmPhonePage from './pages/registration/ConfirmPhonePage'
import UpdateEmailPage from './pages/registration/UpdateEmailPage'
import AuthLoadingPage from './pages/AuthLoadingPage'
import Page from './components/Page'

// Theme
import theme from './consts/Theme'
import { MuiThemeProvider } from '@material-ui/core/styles'

// Store
import useStore from './store/store'

// API
import api from './api/api'

// Actions
import getNetworkStructure from './actions/getNetworkStructure'
import getLogics from './actions/getLogics'
import getDataPanels from './actions/getDataPanels'
import subscribe from './actions/subscribe'

function App() {
  const siteName = 'Glass-Link'
  const setCountries = useStore((state) => state.setCountries)
  const setLoading = useStore((state) => state.setLoading)
  const setFormError = useStore((state) => state.setFormError)
  const networkStructureFetched = useStore(
    (state) => state.networkStructureFetched
  )

  // Effects.
  useEffect(() => {
    // API call.
    const fetchCountries = async () => {
      console.log('Fetching countries')
      setLoading(true)
      try {
        const response = await api.post('/security/get-countries')
        if (response.data.success) {
          setCountries(response.data.countries)
          setLoading(false)
        } else {
          // TODO: Implement.
          setLoading(false)
        }
      } catch (error) {
        console.log(error)
        // Network Error (error code 17)
        if (error.code) setFormError(error.code)
      }
    }
    fetchCountries()

    if (!networkStructureFetched) {
      getNetworkStructure()
      getLogics()
      getDataPanels()
    } else {
      subscribe()
    }
  }, [networkStructureFetched, setCountries, setLoading, setFormError])

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path='/login'
            element={
              <Page title={`${siteName} - Sign In`}>
                <LoginFormPage />
              </Page>
            }
          />
          <Route
            path='/register'
            element={
              <Page title={`${siteName} - Sign Up`}>
                <RegisterFormPage />
              </Page>
            }
          />
          <Route
            path='/confirm'
            element={
              <Page title={`${siteName} - Confirmation`}>
                <ConfirmPhonePage />
              </Page>
            }
          />
          <Route
            path='/update-email'
            element={
              <Page title={`${siteName} - Update Email`}>
                <UpdateEmailPage />
              </Page>
            }
          />
          <Route path='*' element={<AuthLoadingPage />} />
        </Routes>
      </Router>
    </MuiThemeProvider>
  )
}

export default App
