import { sendRequest } from '../api/websocketApi'
import useStore from '../store/store'

const Subscribe = () => {
  const gateways = useStore.getState().gateways
  const networkStateFetched = useStore.getState().networkStateFetched
  const setNetworkStateFetched = useStore.getState().setNetworkStateFetched
  const setNetworkStateCounter = useStore.getState().setNetworkStateCounter
  const setNetworkStateProperties =
    useStore.getState().setNetworkStateProperties
  const setGatewayUuid = useStore.getState().setGatewayUuid
  const setGatewayStatus = useStore.getState().setGatewayStatus

  sendRequest(
    {
      endpoint: '/user/subscribe/network-update',
    },
    (data) => {
      const { success, errCode, stream } = data
      if (stream === 'user-network-update') {
        if (success) {
          console.log(`${stream} subscription successful.`)
        } else {
          console.warn(`${stream} subscription failed: ${errCode}`)
        }
      } else if (gateways.length > 0) {
        if (data && data.gateways && data.gateways.length > 0) {
          if (!networkStateFetched) {
            setNetworkStateProperties(data.gateways[0].properties)
            setNetworkStateCounter(data.gateways[0].counter)
            setGatewayUuid(data.gateways[0].uuid)
            setGatewayStatus(data.gateways[0].status)
            setNetworkStateFetched(true)
          } else {
            setNetworkStateProperties(data.gateways[0].properties)
            setNetworkStateCounter(data.gateways[0].counter)
            setGatewayStatus(data.gateways[0].status)
          }
        }
      }
    },
    { subscription: 'user-network-update' }
  )
}

export default Subscribe
