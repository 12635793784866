import React, { useState } from 'react'

// MUI components
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'

// Actions
import unassignSensor from '../actions/unassignSensor'
import renameSensor from '../actions/renameSensor'

// Store
import useStore from '../store/store'

// Helpers
import isPermissionGranted from '../helpers/isPermissionGranted'

// Consts
import Permissions from '../consts/Permissions'

const SensorMenu = ({ index, name, nodeId }) => {
  // Styles
  const classes = useStyles()

  const [anchor, setAnchor] = useState()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [newName, setNewName] = useState(name || '')
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false)
  const logics = useStore((state) => state.logics)
  const [logicsWarning, setLogicsWarning] = useState('')
  const nodes = useStore((state) => state.nodes)

  const handleClick = (event) => {
    setAnchor(event.currentTarget)
  }

  const handleClose = () => {
    setAnchor(null)
  }

  const handleRenameClick = () => {
    setDialogOpen(true)
    setAnchor(null)
  }

  const handleRemoveClick = () => {
    const nodeIndex = nodes.findIndex((node) => node.NodeId === nodeId)
    if (nodeIndex !== -1) {
      let sensorId = nodes[nodeIndex].Uuid + '.' + index
      let matches = logics.filter(
        (l) =>
          l.JsonLogic.if.property.includes(sensorId) ||
          l.JsonLogic.then.property.includes(sensorId)
      )
      let logicNames = matches.map((l) => l.Name)
      if (logicNames.length > 0) {
        setLogicsWarning(
          'It is used in the following Network Logics: ' +
            logicNames.join(', ') +
            '!'
        )
      }
    }

    setRemoveDialogOpen(true)
    setAnchor(null)
  }

  const handleRemoveDialogConfirm = (nodeId, index) => {
    setRemoveDialogOpen(false)
    unassignSensor(nodeId, index)
  }

  const handleRemoveDialogCancel = () => {
    setRemoveDialogOpen(false)
  }

  const handleRenameDialogCancel = () => {
    setDialogOpen(false)
  }

  const handleRenameDialogSave = () => {
    renameSensor(nodeId, index, newName)
    setDialogOpen(false)
  }

  return (
    <>
      <IconButton
        className={classes.menuButton}
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
        color='primary'
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchor}
        keepMounted
        open={Boolean(anchor)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRenameClick}>Rename...</MenuItem>
        {isPermissionGranted(Permissions.sensor_assignment) && <MenuItem onClick={handleRemoveClick}>Remove...</MenuItem>}
      </Menu>

      {/* RENAME */}
      <Dialog
        open={dialogOpen}
        onClose={handleRenameDialogCancel}
        aria-labelledby='rename-dialog-title'
      >
        <DialogTitle id='rename-dialog-title'>Rename</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            id='name'
            label='Name'
            onChange={(event) => {
              setNewName(event.target.value)
            }}
            margin='dense'
            type='text'
            value={newName}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRenameDialogCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleRenameDialogSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* REMOVE */}
      <Dialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Remove sensor</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove this sensor? {logicsWarning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDialogCancel} color='primary'>
            No
          </Button>
          <Button
            onClick={() => handleRemoveDialogConfirm(nodeId, index)}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SensorMenu

const useStyles = makeStyles((theme) => ({
  menuButton: {
    '&:hover': {
      color: theme.palette.primary.light,
      backgroundColor: 'transparent',
    },
  },
}))
