const Permissions = {
  add_gateway: 'AddGateway',
  add_node: 'AddNode',
  remove_gateway: 'RemoveGateway',
  remove_node: 'RemoveNode',
  sensor_assignment: 'SensorAssignment',
  api_key: 'ApiKey',
  logic_screen: 'LogicScreen',
  show_hidden_properties: 'ShowHiddenProperties',
  dashboard: 'Dashboard',
}

export default Permissions
